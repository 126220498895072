* {
  margin: 0;
}

html{
  scroll-behavior: smooth;
}

body{
  background-color:  #FFF !important;
}

.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #ff94c2 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 102, 212, 0.25) !important;
}
