#profile{
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#profile .password-content{
  text-align: center;
  margin-top: 20px;
}

.password-content .form-pass{
  width: 300px;
  margin-bottom: 10px;
}

button.btn-pass{
  border: none;
  color: #fff;
  margin-top: 10px;
  width: 130px;
  height: 40px;
  background-color: #01062A !important;
  border-radius: 5px !important;
  transition: filter 0.2s;
}

button.btn-pass:disabled{
  opacity: 0.8;
}

button.btn-pass:hover{
  filter: brightness(90%);
}